import React from "react";
import "./notfound.css";
import { Link } from "react-router-dom";

function NotFound(props) {
  return (
    <div>
      <h1> 404 Page Not found </h1>
      <br />
      <Link to="/">Return Home</Link>
    </div>
  );
}

export default NotFound;
