import React, { useState } from "react";

import "../yearContent/yearContent.css";

import axios from "axios";

import { toast } from "react-toastify";
import Progress from "../../components/progress/Progress";
import { FaFileLines } from "react-icons/fa6";
import { FaDownload } from "react-icons/fa";

function NoteContent({ units, notes, selectedSubject }) {
  // let mediaUrl = "http://127.0.0.1:8000";

  //let mediaUrl = "https://backend-jengadaktari.up.railway.app";
  //https://jd-db-backup.s3.amazonaws.com/JDpast_papers/JDpast_papers/Lower%20limb/GROSS_ANATOMY_REVISION.pdf

  const [downloadProgress, setDownloadProgress] = useState(0);

  const filteredUnits = units.filter(
    (unit) => unit.subject === selectedSubject
  );

  const hasNotes = filteredUnits.some((item) =>
    notes.some((note) => item.id === note.unit)
  );

  //showing a toast when the user clicks download button
  const showToast = (message) => {
    message === "Downloading"
      ? toast.success(message)
      : message === "Starting download"
      ? toast.success(message)
      : toast.error(message);
  };

  toast.configure({
    position: "top-right",
  });

  //handling download when a user clicks the download img or the div
  const forceDownload = (response, originalFileName) => {
    //console.log(originalFileName);

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    // Use the original file name as the downloaded file name
    link.setAttribute("download", "JD" + originalFileName);
    document.body.appendChild(link);
    link.click();

    //reset the download progress
    setDownloadProgress(0);
  };
  //when a user clicks the download button lets handle the download here
  const downloadWithAxios = (url, originalFileName) => {
    axios({
      method: "get",
      url,
      responseType: "arraybuffer",

      //for the download progress
      onDownloadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        setDownloadProgress(progress);
      },
    })
      // axios(config)
      .then((response) => {
        forceDownload(response, originalFileName);
        setDownloadProgress(0);
      })
      .catch((error) => {
        // console.log(url);
        // console.log(error);
        error.message === "Network Error"
          ? showToast("Downloading")
          : showToast("something went wrong");

        //showing a toast if user cancels download

        setDownloadProgress(0);
      });
  };

  return (
    <div className="resource__content">
      {downloadProgress > 0 && (
        <div className="downloading__progress">
          <p>Downloading..</p>
          <Progress progress={downloadProgress} />
        </div>
      )}
      <div className="resource__unit__container">
        {/* Check if there are papers for the filtered units */}
        {hasNotes ? (
          filteredUnits.map((item) => {
            // Filter and sort the papers for the current unit by year ,
            //this is inside the map function of filtered units
            const unitNotes = notes
              .filter((note) => item.id === note.unit && note.pdf)
              .sort((a, b) => a.lectureNo - b.lectureNo);

            return (
              <>
                <p className="unit__name">{item.unit}</p>
                <div className="connector"></div>
                <div className="unit__resource__container" key={item.id}>
                  {/* <p className="unit__name">{item.unit}</p> */}

                  <div className="resource__download">
                    {unitNotes.map((note) => (
                      <div
                        onClick={() => {
                          const originalFileName = note.pdf.substring(
                            note.pdf.lastIndexOf("/") + 1
                          );
                          downloadWithAxios(
                            note.pdf, // this is the url of the file in amazon.

                            originalFileName
                          );
                          showToast("Starting download");
                        }}
                        key={note.id}
                      >
                        <p>
                          <FaFileLines className="file-icon" />
                          {note.lectureNo}-
                          {note.pdf
                            .split("/")
                            .pop()
                            .split("_")
                            .slice(0, -1)
                            .join("_")}{" "}
                        </p>

                        <FaDownload className="download-icon" />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            );
          })
        ) : (
          // if then there are no notes display this
          <p>No Notes uploaded yet</p>
        )}
      </div>
    </div>
  );
}

export default NoteContent;
