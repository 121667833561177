import React from "react";
import "./home.css";

import { Header, AboutUs, CTA, Testimonials, Footer } from "../../containers";
import { NavBar } from "../../components";

function Home(props) {
  const navLinks = [
    {
      label: "About Us",
      link: "#aboutUs",
    },
    { label: "Explore", link: "#cta" },
  ];
  return (
    <div className="App">
      <div>
        <div className="hero">
          <NavBar navLinks={navLinks} />
          <Header />
        </div>
        <AboutUs />
        <CTA />
        <Testimonials />

        <Footer />
      </div>
    </div>
  );
}

export default Home;
