import React from "react";
import "./footer.css";
import logo from "../../assets/logo.png";

import { Link } from "react-router-dom";

import { MdEmail } from "react-icons/md";
import { FaWhatsapp, FaHeart } from "react-icons/fa";

function Footer(props) {
  const linkStyle = {
    textDecoration: "none",
    //color: " #95b0c9",
    color: "white",
    fontFamily: "var(--font-family)",
  };

  const currentYear = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="footer__content">
        <Link to="/">
          <div className="footer__logo">
            <img src={logo} alt="logo" />
          </div>
        </Link>
        <div className="footer__quicklinks">
          <h1>Quick Links</h1>
          <div className="border-bottom gradient-bg"></div>
          <p>
            <a style={linkStyle} href="#navbar">
              Back to Top
            </a>
          </p>

          <p>
            <Link style={linkStyle} to="/year1">
              Year 1
            </Link>
          </p>

          <p>
            {" "}
            <Link style={linkStyle} to="/year2">
              Year 2
            </Link>
          </p>
          <p>
            <Link style={linkStyle} to="/year3">
              Year 3
            </Link>
          </p>
          <p>
            <Link style={linkStyle} to="/year4">
              Year 4
            </Link>
          </p>
          <p>
            <Link style={linkStyle} to="/year5">
              Year 5
            </Link>
          </p>
          <p>
            <Link style={linkStyle} to="/year6">
              Year 6
            </Link>
          </p>

          <p>
            <Link style={linkStyle} to="/blog">
              Blog
            </Link>
          </p>

          <p>
            <Link style={linkStyle} to="/research">
              Research
            </Link>
          </p>
        </div>

        <div className="footer__contacts">
          <h1>Contact us</h1>
          <div className="border-bottom gradient-bg"></div>
          <div>
            <FaWhatsapp className="footer-icon " />
            <p>
              <a style={linkStyle} href="https://wa.me/254757698228">
                Chat with us
              </a>
            </p>
          </div>
          <div>
            <MdEmail className="footer-icon" />
            <p>
              <a style={linkStyle} href="mailto:jengadaktari@gmail.com">
                jengadaktari@gmail.com
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="footer__end">
        <div>
          Made with <FaHeart className="footer-heart" /> by Emmanuel Riri
        </div>
        <p>Copyright © Jenga Daktari {currentYear}. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
