import React from "react";
import "./year.css";
import { Link } from "react-router-dom";

function Year({ img, year }) {
  return (
    <div>
      <Link to={year.replace(/\s+/g, "")}>
        <div className="year__box">
          <img src={img} alt={img} />
          <p>{year}</p>
        </div>
      </Link>
    </div>
  );
}

export default Year;
