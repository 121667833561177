import React from "react";
import "./testimonials.css";

import { Testimony } from "../../components";

function Testimonials(props) {
  return (
    <div className="testimonials">
      <div className="testimonial__text">
        <p>Testimonials</p>

        <h2>What did Medical Students say about us?</h2>
        <p>
          Read what fellow medical students have to say about Jenga Daktari.
        </p>
      </div>
      <div className="testimonial__container">
        <Testimony
          text={
            "Grateful to Emmanuel for creating a valuable medical academic hub to support students.Let's make a life by what we give through this slow but sure race; quitting won't expedite it."
          }
          name={"D.Murimi "}
          position={"Mbchb 3rd year, MKU"}
        />
        <Testimony
          text={
            "Jenga Daktari has been a game-changer in my academic journey. It simplifies access to past papers and study materials, improving study efficiency . Highly recommended for any medical student"
          }
          name={"N.Bett "}
          position={"Mbchb 4th year, MKU"}
        />
        <Testimony
          text={
            "Jenga Daktari app: A game-changer for medical students, streamlining exam prep, boosting study efficiency, and achieving academic excellence."
          }
          name={"D.Kamiri"}
          position={"Mbchb 3rd year, MKU"}
        />
      </div>
    </div>
  );
}

export default Testimonials;
