import React, { useRef, useState } from "react";
import "./upload.css";
// import upload from "../../assets/upload.svg";
import Progress from "../progress/Progress";

import axios from "axios";
import { toast } from "react-toastify";
import { useUploadContext } from "../../context/UploadContext";
import { API_ENDPOINTS } from "../../api/services/api";

function Upload({ subjects, units, uploading }) {
  const [filename, setFilename] = useState("");

  //these are the input fields for the form
  const [paperYear, setPaperYear] = useState("");

  const [lectureNumber, setLectureNumber] = useState("");
  const [selectedUnitId, setSelectedUnitId] = useState("");

  const years = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023];
  const lectureNo = [
    "lec 1",
    "lec 2",
    "lec 3",
    "lec 4",
    "lec 5",
    "lec 6",
    "lec 7",
    "lec 8",
    "lec 9",
    "extra notes",
  ];

  const [selectedSubjectObject, setSelectedSubjectObject] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  //this is so that if a file is uploaded the get units in the yearPage gets called
  const { setUploadSuccessStatus } = useUploadContext();

  //we are using useRef inorder to reset the fields after a file upload
  const formRef = useRef(null);

  //the value for the drag over when the user wants to upload a file
  const [isDragOver, setIsDragOver] = useState(false);
  // getting the object of the subject that has been selected
  const handleSubjectChange = (e) => {
    const selectedSubject = e.target.value;

    const selectedSubjectObject = subjects.find(
      (subject) => subject.subject === selectedSubject
    );
    setSelectedSubjectObject(selectedSubjectObject);
  };

  // after the user selects a subject we check if the unit subject matches with the selected subject
  // this will ensure to dispay the units corresponding to that subject that has been selected
  const filteredUnits = selectedSubjectObject
    ? units.filter((unit) => unit.subject === selectedSubjectObject.id)
    : [];

  const handleUnitChange = (e) => {
    const selectedUnit = units.find((unit) => unit.unit === e.target.value);
    setSelectedUnitId(selectedUnit ? selectedUnit.id : "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("unit", selectedUnitId);

    // if uploading papers , let's append the year and if uploading papers let's append the lectureNo
    uploading === "papers"
      ? formData.append("year", paperYear)
      : formData.append("lectureNo", lectureNumber);

    formData.append("pdf", filename);

    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        setUploadProgress(progress);
      },
    };

    // Sending the formData to the backend with progress tracking
    //if we are uploading the notes let's send the data to postPapersApi else let's send the data to the PostNotes API
    axios
      .post(
        uploading === "papers"
          ? API_ENDPOINTS.POSTPAPERS
          : API_ENDPOINTS.POSTNOTES,
        formData,
        axiosConfig
      )
      .then((response) => {
        console.log(response);

        showToast("File Uploaded Successfully");
        // if its a success set the form fields to normal
        setFilename("");
        setPaperYear("");
        setLectureNumber("");
        setSelectedUnitId("");

        setUploadProgress(0);
        setUploadSuccessStatus(true);

        // Reset the form to clear input fields
        formRef.current.reset();
      })
      .catch((error) => {
        showToast("Something Went Wrong");
      });
  };

  const handleFileChange = (e) => {
    let newFilename = e.target.files[0];

    setFilename(newFilename);
  };

  // for the drag and drop

  //fumction runs when there is a drag over event
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  //when there is no drag over prevent the default
  // and set DragOver to false
  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  //when user drops
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    // Handle the dropped files and store them in files
    const files = e.dataTransfer.files;

    // You can also trigger the file input by its ID if needed
    const fileInput = document.getElementById(
      uploading === "papers" ? "paperFile" : "notesFile"
    );
    if (fileInput) {
      fileInput.files = files;
      // Trigger the change event to handle the files as usual
      handleFileChange({ target: fileInput });
    }
  };

  // end of drag and drop

  // showing a toast incase of either a successful or fail
  const showToast = (message) => {
    message === "File Uploaded Successfully"
      ? toast.success(message)
      : toast.error(message);
  };

  return (
    <div
      className={`upload__area ${isDragOver ? "dragover" : ""}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <p className="font-weight-bold">
        Upload files here or Drag and drop files here
      </p>
      <form ref={formRef}>
        <div className="mb-3 Upload__box">
          <input
            onChange={(e) => {
              handleFileChange(e);
            }}
            type="file"
            name="file"
            id={uploading === "papers" ? "paperFile" : "notesFile"}
            accept=".pdf, .doc, .docx"
            className="form-control form-control-sm"
          />
        </div>

        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <label className="input-group-text" htmlFor="subjectSelect">
              Topic:
            </label>
          </div>
          <select
            className="custom-select"
            onChange={handleSubjectChange}
            id="subjectSelect"
            name="subject"
          >
            <option>none</option>
            {subjects.map((subject) => (
              <option key={subject.id} value={subject.subject}>
                {subject.subject}
              </option>
            ))}
          </select>
        </div>

        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <label className="input-group-text" htmlFor="unitSelect">
              Unit:
            </label>
          </div>
          <select
            className="custom-select"
            onChange={handleUnitChange}
            id="unitSelect"
            name="unit"
          >
            <option>none</option>
            {filteredUnits.map((unit) => (
              <option key={unit.id} value={unit.unit}>
                {unit.unit}
              </option>
            ))}
          </select>
        </div>

        {/* if uploading == papers , show the year option else show the lecture option */}
        {uploading === "papers" ? (
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="yearSelect">
                Year:
              </label>
            </div>
            <select
              className="custom-select"
              onChange={(e) => setPaperYear(e.target.value)}
              htmlFor="yearSelect"
              name="year"
            >
              <option>none</option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="lectureNoSelect">
                Lecture No:
              </label>
            </div>
            <select
              className="custom-select"
              onChange={(e) => setLectureNumber(e.target.value)}
              htmlFor="lectureNoSelect"
              name="lectureNo"
            >
              <option>none</option>
              {lectureNo.map((lecture) => (
                <option key={lecture} value={lecture}>
                  {lecture}
                </option>
              ))}
            </select>
          </div>
        )}

        <button
          onClick={(e) => handleSubmit(e)}
          type="submit "
          className={
            (selectedUnitId && filename && paperYear) || lectureNumber
              ? "btn btn-primary submit-btn"
              : "btn disabled"
          }
        >
          Submit
        </button>
        <br />
      </form>
      {uploadProgress ? <Progress progress={uploadProgress} /> : null}
    </div>
  );
}

export default Upload;
