import React, { useState } from "react";
import "./listGroup.css";

function ListGroup({ subjects, onSubjectClick }) {
  const [activeItemId, setActiveItemId] = useState(null);

  const handleItemClick = (subject) => {
    setActiveItemId(subject.id); // Set the active item ID
    onSubjectClick(subject.id); // Call the parent component's callback function
  };
  return (
    <div>
      <ul className="list-group list__unit">
        {subjects.map((subject) => (
          <li
            onClick={() => handleItemClick(subject)}
            className={`list-group-item ${
              subject.id === activeItemId ? "active" : ""
            }`}
            key={subject.id}
          >
            {subject.subject}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ListGroup;
