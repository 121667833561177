import React, { useEffect, useState } from "react";

import "./research.css";
import { gapi } from "gapi-script";
import { NavBar } from "../../components";
import { Footer } from "../../containers";

import { FaFolderOpen } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const CLIENT_ID =
  "526635787899-nflla003eh1ha7273u8pkrabg866u8fv.apps.googleusercontent.com";

const API_KEY = "AIzaSyADVmc2A29CExmuzKqsYDv6YIc9LLC0ASA";

function Accordion({ title, content }) {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const linkStyle = {
    textDecoration: "none",

    fontFamily: "var(--font-family)",
  };

  return (
    <div className="">
      <div
        className={isOpen ? "active-accordion accordion" : "accordion"}
        onClick={toggleAccordion}
      >
        <div className="accordion__header">
          {" "}
          {title} {isOpen ? <p> -</p> : <p>+</p>}
        </div>
      </div>
      {isOpen && (
        <div className="accordion__content">
          {content.map((folder) => (
            <div className="accordion__content__link" key={folder.id}>
              <FaFolderOpen /> -{" "}
              <Link style={linkStyle} to={folder.webViewLink} target="_blank">
                {folder.name}
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function Research(props) {
  const [folders, setFolders] = useState([]);

  const FOLDER_ID = "154ZJLj-L4vvdte9Yp75rcPRciVgjFjxv";

  useEffect(() => {
    const initClient = () => {
      gapi.client
        .init({
          clientId: CLIENT_ID,
          apiKey: API_KEY,
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
          ],
          scope: "https://www.googleapis.com/auth/drive",
        })
        .then(() => {
          // Google API client is initialized, you can now use gapi.client.drive
          // folder from where i want to see the files
          listFoldersInParentFolder(FOLDER_ID);
        });
    };

    // function to list files in folder
    const listFoldersInParentFolder = (FolderId) => {
      gapi.client.drive.files
        .list({
          // q: `'${folderId}' in parents`,
          q: `'${FolderId}' in parents and mimeType='application/vnd.google-apps.folder'`,
          fields: "files(id, name,webViewLink)",
          orderBy: "folder, name",
          pageSize: 10,
        })
        .then((response) => {
          const folders = response.result.files;
          if (folders && folders.length > 0) {
            setFolders(folders);
            console.log(folders);
          } else {
            console.log("No folders found.");
          }
        })
        .catch((error) => {
          console.error("Error listing folders:", error);
        });
    };

    gapi.load("client:auth2", initClient);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Research </title>
        <meta
          name="description"
          content="The Introduction to the Principles and Practice of Clinical
              Research (IPPCR) course trains registrants on how to effectively
              and safely conduct clinical research. The course focuses on the
              spectrum of clinical research and the research process by
              highlighting biostatistical and epidemiologic methods, study
              design, protocol preparation, patient monitoring, quality
              assurance, ethical and legal issues, and much more."
        />
        <meta property="og:title" content="Research" />
        <meta
          property="og:description"
          content="The Introduction to the Principles and Practice of Clinical
              Research (IPPCR) course trains registrants on how to effectively
              and safely conduct clinical research. The course focuses on the
              spectrum of clinical research and the research process by
              highlighting biostatistical and epidemiologic methods, study
              design, protocol preparation, patient monitoring, quality
              assurance, ethical and legal issues, and much more."
        />
      </Helmet>
      <div className="blogPage__header">
        <NavBar navLinks={null} />
      </div>

      <div className="research-container">
        <section className="research-content">
          <div>
            <h2 className="course__header">
              SCORE in collaboration with Jenga Daktari.{" "}
            </h2>
            <p>
              The Introduction to the Principles and Practice of Clinical
              Research (IPPCR) course trains registrants on how to effectively
              and safely conduct clinical research. The course focuses on the
              spectrum of clinical research and the research process by
              highlighting biostatistical and epidemiologic methods, study
              design, protocol preparation, patient monitoring, quality
              assurance, ethical and legal issues, and much more.
              <br />
              <br />
              Credits:{" "}
              <a href="https://ocreco.od.nih.gov/courses/ippcr.html">
                Visit the official website for more
              </a>
            </p>
            {/* <ul>
              {folders.map((folder) => (
                <li key={folder.id}>
                  {folder.name} and the link is {folder.webViewLink}
                </li>
              ))}
            </ul> */}
            <Accordion title="NIH IPPCR" content={folders} />
            <div>
              <h4 className="course__header">Alison</h4>
              <p>
                Alison is one of the world's largest free learning platforms for
                education and skills training. It's a for-profit social
                enterprise dedicated to making it possible for anyone to study
                anything ,anywhere ,at any time , for free online , at any
                subject level. <br />
                For more information on how to get started on alison please read
                the following{" "}
                <a href="https://jengadaktari.org/blog/how-use-alison-for-research">
                  blog
                </a>
              </p>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default Research;
