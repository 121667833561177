import React, { useState } from "react";
import "./yearTab.css";

function YearTab({ onTabClick }) {
  const [activeTab, setActiveTab] = useState("papers");

  const handleTabClick = (activeTab) => {
    setActiveTab(activeTab);
    onTabClick(activeTab);
    console.log(`active tab is ${activeTab}`);
  };
  return (
    <div className="tab">
      <button
        onClick={() => handleTabClick("papers")}
        className={`tablinks ${activeTab === "papers" ? "active" : ""}`}
      >
        Papers
      </button>
      <button
        onClick={() => handleTabClick("notes")}
        className={`tablinks ${activeTab === "notes" ? "active" : ""}`}
      >
        Notes
      </button>
      <button
        onClick={() => handleTabClick("videos")}
        className={`tablinks ${activeTab === "videos" ? "active" : ""}`}
      >
        Videos
      </button>
      {/* eha */}
    </div>
  );
}

export default YearTab;
