import React, { useEffect, useState } from "react";
import { NavBar } from "../../components";
import { Footer, BlogBanner } from "../../containers";
import "./blog.css";

import { IoMdTime } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";
import { API_ENDPOINTS } from "../../api/services/api";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Blog(props) {
  const [featured, setFeatured] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [lastBlog, setLastBlog] = useState([]);

  const linkStyle = {
    textDecoration: "none",
  };

  useEffect(() => {
    getBlogs();
    getFeatured();

    console.log("Bck");
  }, []);

  let getBlogs = async () => {
    try {
      let response = await axios(API_ENDPOINTS.BLOGS, {
        method: "GET",
        headers: {
          "content-Type": "application/json",
        },
      });
      let data = await response.data;

      setBlogs(data);
      setLastBlog(data[data.length - 1]);
      console.log(data);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  let getFeatured = async () => {
    try {
      let response = await axios(API_ENDPOINTS.FEATURED, {
        method: "GET",
        headers: {
          "content-Type": "application/json",
        },
      });
      let data = await response.data;

      setFeatured(data[0]);
    } catch (error) {
      console.error("Error fetching featured blog:", error);
    }
  };

  //for the recent blogs i want to get the last three blogs
  const lastThreeBlogs = blogs.slice(-3);

  // const lastBlog = blogs[blogs.length - 1];

  // var blogLength = parseInt(blogs.length);
  // const lastBlog = blogs[blogLength - 1];

  // if (blogs) {
  //   var lastBlog = blogs[1];
  // } else {
  //   null;
  // }

  console.log(lastBlog);
  console.log(`number of blogs is  ${blogs.length}`);

  return (
    <div>
      <Helmet>
        <title>Jenga Daktari Blog </title>
      </Helmet>

      <div className="blogPage__header">
        <NavBar navLinks={null} />
      </div>

      <BlogBanner />

      <section className="pb-0 section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-5">
              <h2 className="h5 section-title">Featured Post</h2>
              <article className="shadow article-content">
                <img
                  className="post-image"
                  src={featured.thumbnail}
                  alt="thumbnail"
                />
                <div className="card-details">
                  <Link style={linkStyle} to={`/blog/${featured.slug}`}>
                    <h3 className="post-title">{featured.title}</h3>
                  </Link>

                  <ul class="card-meta list-inline">
                    <li class="list-inline-item">
                      <div>
                        <img
                          className="author-photo"
                          src={featured.authorProfilePic}
                          alt=""
                        />
                        <span className="light-text">
                          {featured.authorName} |
                        </span>
                      </div>
                    </li>
                    <li class="list-inline-item ">
                      <IoMdTime className="light-text" />
                      <span className="light-text">
                        {" "}
                        {featured.readTime} Min Read |
                      </span>
                    </li>
                    <li class="list-inline-item">
                      <CiCalendarDate className="light-text" />
                      <span className="light-text">
                        {featured.date_created} |
                      </span>
                    </li>
                    <li class="list-inline-item">
                      <ul class="card-meta-tag list-inline">
                        <li class="list-inline-item ">
                          <Link
                            to={`/blog/category/${featured.category}`}
                            className="card-meta-tag-category"
                            style={linkStyle}
                          >
                            {featured.category}
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <p className="post-description-text">
                    {featured.description}
                  </p>
                  <Link style={linkStyle} to={`/blog/${featured.slug}`}>
                    <p className=" card-meta-button">Read more</p>
                  </Link>
                </div>
              </article>
            </div>

            <div className="col-lg-4 mb-5">
              <h2 className="h5 section-title">Recent Posts</h2>

              {lastThreeBlogs.map((blog) => (
                <div className="card-body shadow" key={blog.id}>
                  <img className="card-img-sm" src={blog.thumbnail} alt="" />
                  <div className="card-body-post-details">
                    <Link style={linkStyle} to={`/blog/${blog.slug}`}>
                      <h3 className="post-title">{blog.title}</h3>
                    </Link>
                    <ul class="card-meta list-inline">
                      <li class="list-inline-item ">
                        <IoMdTime className="light-text" />
                        <span className="light-text">
                          {" "}
                          {blog.readTime} Min Read |
                        </span>
                      </li>
                      <li class="list-inline-item">
                        <CiCalendarDate className="light-text" />
                        <span className="light-text">
                          {blog.date_created} |
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>

            <div className="col-lg-4 mb-5">
              <h2 className="h5 section-title">Popular Posts</h2>

              <article className="shadow article-content">
                <img
                  className="post-image"
                  src={lastBlog.thumbnail}
                  alt="thumbnail"
                />
                <div className="card-details">
                  <Link style={linkStyle} to={`/blog/${lastBlog.slug}`}>
                    <h3 className="post-title">{lastBlog.title}</h3>
                  </Link>

                  <ul class="card-meta list-inline">
                    <li class="list-inline-item">
                      <div>
                        <img
                          className="author-photo"
                          src={lastBlog.authorProfilePic}
                          alt=""
                        />
                        <span className="light-text">
                          {lastBlog.authorName} |
                        </span>
                      </div>
                    </li>
                    <li class="list-inline-item ">
                      <IoMdTime className="light-text" />
                      <span className="light-text">
                        {" "}
                        {lastBlog.readTime} Min Read |
                      </span>
                    </li>
                    <li class="list-inline-item">
                      <CiCalendarDate className="light-text" />
                      <span className="light-text">
                        {lastBlog.date_created} |
                      </span>
                    </li>
                    <li class="list-inline-item">
                      <ul class="card-meta-tag list-inline">
                        <li class="list-inline-item ">
                          <Link
                            to={`/blog/category/${lastBlog.category}`}
                            className="card-meta-tag-category"
                            style={linkStyle}
                          >
                            {lastBlog.category}
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <p className="post-description-text">
                    {lastBlog.description}
                  </p>
                  <Link style={linkStyle} to={`/blog/${lastBlog.slug}`}>
                    <p className=" card-meta-button">Read more</p>
                  </Link>
                </div>
              </article>
            </div>

            <div className="col-12 border-bottom border-default"></div>

            <section className="section-sm">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8 mb-5 mb-lg-0">
                    <h2 className="h5 section-title">Recent Posts</h2>

                    {blogs.map((blog) => (
                      <article
                        key={blog.id}
                        className="card blog-detail shadow mb-4"
                      >
                        <div className="  row card-body">
                          <div className="col-md-4 mb-4 mb-md-0 blog-detail-thumbnail ">
                            <img
                              className="article-img"
                              src={blog.thumbnail}
                              alt=""
                            />
                          </div>
                          <div className="col-md-8">
                            <Link style={linkStyle} to={`/blog/${blog.slug}`}>
                              <h3 className="post-title">{blog.title}</h3>
                            </Link>
                            <ul class="card-meta list-inline">
                              <li class="list-inline-item">
                                <div>
                                  <img
                                    className="author-photo"
                                    src={blog.authorProfilePic}
                                    alt=""
                                  />
                                  <span className="light-text">
                                    {blog.authorName} |
                                  </span>
                                </div>
                              </li>
                              <li class="list-inline-item ">
                                <IoMdTime className="light-text" />
                                <span className="light-text">
                                  {" "}
                                  {blog.readTime} Min Read |
                                </span>
                              </li>
                              <li class="list-inline-item">
                                <CiCalendarDate className="light-text" />
                                <span className="light-text">
                                  {blog.date_created} |
                                </span>
                              </li>
                              <li class="list-inline-item">
                                <ul class="card-meta-tag list-inline">
                                  <li class="list-inline-item ">
                                    <Link
                                      to={`/blog/category/${blog.category}`}
                                      className="card-meta-tag-category"
                                      style={linkStyle}
                                    >
                                      {blog.category}
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                            </ul>

                            <p>{blog.description}</p>

                            <Link style={linkStyle} to={`/blog/${blog.slug}`}>
                              <p className=" card-meta-button">Read more</p>
                            </Link>
                          </div>
                        </div>
                      </article>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Blog;
