import React from "react";
import YearPage from "./YearPage";
import { Helmet } from "react-helmet";

function year2Page(props) {
  return (
    <div>
      <Helmet>
        <title>Year 2</title>
      </Helmet>
      <YearPage year={2} />
    </div>
  );
}

export default year2Page;
