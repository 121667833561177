import React from "react";
import YearPage from "./YearPage";
import { Helmet } from "react-helmet";

function Year1Page(props) {
  return (
    <div>
      <Helmet>
        <title>Year 1</title>
      </Helmet>
      <YearPage year={1} />
    </div>
  );
}

export default Year1Page;
