import React from "react";
import "./header.css";

function Header(props) {
  return (
    <div className="header header__margin">
      <div className="header__jdword">
        <span className="jenga">Jenga</span>{" "}
        <span className="daktari">Daktari.</span>
      </div>
      <div className="header__maintext">
        Unlock the Power of MKU Med School's Learning Hub , Where All Your
        Resources Come Together.
      </div>
    </div>
  );
}

export default Header;
