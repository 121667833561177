// api/services/api.js
const BASE_URL = "https://backend-jengadaktari.up.railway.app/";
// const LOCAL_URL = "http://127.0.0.1:8000/";

export const API_ENDPOINTS = {
  SUBJECTS: `${BASE_URL}subjects/`,
  UNITS: `${BASE_URL}units/`,
  PAPERS: `${BASE_URL}papers/`,
  NOTES: `${BASE_URL}notes/`,
  POSTPAPERS: `${BASE_URL}api/papers/`,
  POSTNOTES: `${BASE_URL}api/notes/`,

  //the blog urls here
  BLOGS: `${BASE_URL}api/blog/`,
  FEATURED: `${BASE_URL}api/blog/featured/`,
  CATEGORY: `${BASE_URL}api/blog/category/`,
};
