import React from "react";
import "./App.css";
import Home from "./pages/homepage/Home";
import { UploadProvider } from "./context/UploadContext";
import { NotFound } from "./components";

import { Route, Routes, Navigate } from "react-router-dom";
import Year1Page from "./pages/year/Year1Page";
import Year2Page from "./pages/year/Year2Page";
import Year4Page from "./pages/year/Year4Page";
import Year3Page from "./pages/year/Year3Page";
import Year5Page from "./pages/year/Year5Page";
import Year6Page from "./pages/year/Year6Page";
import Blog from "./pages/blog/Blog";
import { ToastContainer } from "react-toastify";
import BlogCategoryPage from "./pages/blog/BlogCategoryPage";
import BlogDetailPage from "./pages/blog/BlogDetailPage";
import Research from "./pages/research/Research";

function App() {
  return (
    <UploadProvider>
      <Routes>
        <Route element={<Home />} path="" />
        <Route element={<Year1Page />} path="/year1" />
        <Route element={<Year2Page />} path="/year2" />
        <Route element={<Year3Page />} path="/year3" />
        <Route element={<Year4Page />} path="/year4" />
        <Route element={<Year5Page />} path="/year5" />
        <Route element={<Year6Page />} path="/year6" />
        <Route element={<Blog />} path="/blog" />
        <Route element={<BlogCategoryPage />} path="/blog/category/:id" />
        <Route element={<BlogDetailPage />} path="/blog/:id" />
        <Route element={<Research />} path="/research" />
        <Route element={<NotFound />} path="/not-found" />
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Routes>
      <ToastContainer />
    </UploadProvider>
  );
}

export default App;
