import React from "react";
import "./testimony.css";

import { FaUserCircle } from "react-icons/fa";

function Testimony({ text, name, position }) {
  return (
    <div className="testimony">
      <p className="testimony-text">{text}</p>
      <div className="user-details">
        <FaUserCircle className="user-icon" />
        <div className="flex user-name">
          <p>{name}</p>
          <p className="gray-text">{position}</p>
        </div>
      </div>
    </div>
  );
}

export default Testimony;
