import React, { useState } from "react";
import "./navbar.css";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";

import { RiMenu3Line, RiCloseLine } from "react-icons/ri";

function NavBar({ navLinks }) {
  const [toogleMenu, setToggleMenu] = useState(false);
  const linkStyle = {
    textDecoration: "none",
    //color: " #95b0c9",
    // color: "white",
    fontFamily: "var(--font-family)",
  };

  return (
    <div className="navbar" id="navbar">
      <div className="navbar__links">
        <Link to="/">
          <div className="navbar__links-logo">
            <img src={logo} alt="logo" />
          </div>
        </Link>
        <div className="navbar__links-container">
          <p className="navbar__link-home">
            <Link style={linkStyle} to="/">
              Home
            </Link>
          </p>

          <p>
            <div className="dropdown">
              <p style={linkStyle} className="dropbtn">
                Years
              </p>
              <div class="dropdown-content">
                <Link to="/year1">Year 1</Link>
                <Link to="/year2">Year 2</Link>
                <Link to="/year3">Year 3</Link>
                <Link to="/year4">Year 4</Link>
                <Link to="/year5">Year 5</Link>
                <Link to="/year6">Year 6</Link>
              </div>
            </div>
          </p>

          {navLinks &&
            navLinks.map((item, index) => (
              <p className="navbar__links-text" key={item.link}>
                <a style={linkStyle} href={item.link}>
                  {item.label}{" "}
                </a>
              </p>
            ))}
          <p className="navbar__links-text">
            <Link style={linkStyle} to="/blog">
              Blog
            </Link>
          </p>

          <p className="navbar__links-text">
            <Link style={linkStyle} to="/research">
              Research
            </Link>
          </p>
        </div>
      </div>
      {/*so we display the 3 lines first , if they are clicked toogle items appear
       */}
      <div className="navbar-menu">
        {toogleMenu ? (
          <RiCloseLine
            color="#020b18"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#020b18"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {/*if there is a toogle menu go ahead and display the following items */}

        {toogleMenu && (
          <div className="navbar-menu_container scale-up-center">
            {" "}
            <p className="menu__navbar__link-home">
              <Link to="/">Home</Link>
            </p>
            <div className="navbar-menu_container-links"></div>{" "}
            <div className="menu_dropdown">
              <p style={linkStyle} className="menu_dropbtn ">
                Years
              </p>
              <div class=" menu_dropdown-content">
                <Link to="/year1">Year 1</Link>
                <Link to="/year2">Year 2</Link>
                <Link to="/year3">Year 3</Link>
                <Link to="/year4">Year 4</Link>
                <Link to="/year5">Year 5</Link>
                <Link to="/year6">Year 6</Link>
              </div>
            </div>
            {navLinks &&
              navLinks.map((item, index) => (
                <p className="menu_navbar__links-text" key={item.link}>
                  <a href={item.link}>{item.label} </a>
                </p>
              ))}
            <p className="">
              <Link style={linkStyle} to="/blog">
                Blog
              </Link>
            </p>
            <p className="">
              <Link style={linkStyle} to="/research">
                Research
              </Link>
            </p>
            <div className="navbar-menu_container-links-home"></div>{" "}
          </div>
        )}
      </div>
    </div>
  );
}

export default NavBar;
