import React from "react";
import "./aboutUs.css";

import study from "../../assets/study 1.jpg";

import { FaLaptop, FaUpload } from "react-icons/fa";
import { PiStudentFill } from "react-icons/pi";
import { FaDownload } from "react-icons/fa6";

function AboutUs(props) {
  return (
    <div className="aboutUs" id="aboutUs">
      <img className="aboutUs__image" src={study} alt="aboutUs" />

      <div className="aboutUs__text">
        <p>About us</p>

        <h2>
          {" "}
          Explore a Wealth of Med School Resources, Past Papers, and Study Notes{" "}
        </h2>
        <p className="gray-text about-description">
          Our website offers a variety of med school learning materials like
          past papers and study notes. You can easily share and download past
          papers here.
        </p>

        {/* about us icons  */}
        <div className="aboutUs-icons">
          {/* one icon */}
          <div className="flex icons-container">
            <div className="about-icon-container laptop-icon-container">
              <FaLaptop className="about-icon laptop-icon" />
            </div>
            <p>Online learning</p>
          </div>

          {/* one icon */}
          <div className="flex icons-container">
            <div className="about-icon-container student-icon-container">
              <PiStudentFill className="about-icon student-icon" />
            </div>
            <p>Research</p>
          </div>
          {/* one icon */}
          <div className="flex icons-container">
            <div className="about-icon-container upload-icon-container">
              <FaUpload className="about-icon upload-icon" />
            </div>
            <p>Upload Materials</p>
          </div>
          {/* one icon */}
          <div className="flex icons-container">
            <div className="about-icon-container download-icon-container">
              <FaDownload className="about-icon download-icon" />
            </div>
            <p>Download Materials</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
