import React, { useState, useEffect } from "react";

import "./scrollProgress.css";

function ScrollProgress(props) {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const fullHeight = document.body.clientHeight - windowHeight;
    const scrolled = window.scrollY;
    const percentage = (scrolled / fullHeight) * 100;
    setScrollPercentage(percentage);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="scroll-progress-container">
      <div
        className="scroll-progress-bar"
        style={{ width: `${scrollPercentage}%` }}
      />
    </div>
  );
}

export default ScrollProgress;
