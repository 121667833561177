import { createContext, useContext, useState } from "react";

const UploadContext = createContext();

//function that we can use inorder to access the data
//stored in uploadcontext
export function useUploadContext() {
  return useContext(UploadContext);
}

//wraps other components and provides them with access to contexts data

export function UploadProvider({ children }) {
  // this is the data i want to share with other components
  const [uploadSuccess, setUploadSuccess] = useState(false);

  // this function allows us to update uploadSuccess state
  const setUploadSuccessStatus = (status) => {
    setUploadSuccess(status);
  };

  return (
    <UploadContext.Provider value={{ uploadSuccess, setUploadSuccessStatus }}>
      {children}
    </UploadContext.Provider>
  );
}
