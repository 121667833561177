import React, { useEffect, useState } from "react";
import { NavBar, ScrollProgress } from "../../components";
import { Footer, BlogDetail } from "../../containers";
import "./blog.css";
import { useParams } from "react-router-dom";
import { API_ENDPOINTS } from "../../api/services/api";
import axios from "axios";

function BlogDetailPage(props) {
  const [blog, setBlog] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const slug = id;

    const fetchData = async () => {
      try {
        const res = await axios.get(API_ENDPOINTS.BLOGS + slug);

        setBlog(res.data);
      } catch (err) {}
    };
    fetchData();
  }, [id]);

  const createBlog = () => {
    //return { __html: blog.content };
    if (blog.content) {
      const modifiedBlogContent = blog.content
        .replace(
          /<table/g,
          '<table style="width:90%;height:90%;" class="table"'
        )
        .replace(
          /<img/g,
          '<img style="width:90%;height:90%;" class="content-blog-img"'
        )
        // Apply font-family and font-size inline styles
        .replace(
          /<([a-z][a-z0-9]*)/g,
          "<$1 style=\"font-family: 'Roboto', sans-serif; font-size: 14px;\""
        );

      return { __html: modifiedBlogContent };
    } else {
      return null;
    }
  };

  return (
    <div>
      <ScrollProgress />
      <div className="blogPage__header">
        <NavBar navLinks={null} />
      </div>

      <BlogDetail blog={blog} createBlog={createBlog} />
      <Footer />
    </div>
  );
}

export default BlogDetailPage;
