import React from "react";
import YearPage from "./YearPage";
import { Helmet } from "react-helmet";

function year3Page(props) {
  return (
    <div>
      <Helmet>
        <title>Year 3</title>
      </Helmet>
      <YearPage year={3} />
    </div>
  );
}

export default year3Page;
