import React from "react";
import "./yearPage.css";
import { ListGroup, NavBar, Upload, YearTab, Loading } from "../../components";
import { Footer, YearContent, NoteContent } from "../../containers";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useUploadContext } from "../../context/UploadContext";
import { API_ENDPOINTS } from "../../api/services/api";

function YearPage({ year }) {
  let [subjects, setSubjects] = useState([]);
  let [units, setUnits] = useState([]);
  let [papers, setPapers] = useState([]);
  let [notes, setNotes] = useState([]);

  const [selectedSubject, setSelectedSubject] = useState(null);
  const [filteredUnitsBySearch, setFilteredUnitsBySearch] = useState([]);
  const [loading, setLoading] = useState(true);

  // for changing of the tabs
  const [selectedTab, setSelectedTab] = useState("papers");

  //if there is a paper that has been uploaded call the get units function to update the units

  const { uploadSuccess, setUploadSuccessStatus } = useUploadContext();
  useEffect(() => {
    getSubjects();
    getUnits();
    getPapers();
    getNotes();

    //this is to update the units incase there is a file that has been updated
    if (uploadSuccess) {
      getUnits();
      setUploadSuccessStatus(false);
    }
    console.log("Bck");
  }, [uploadSuccess]);

  let getSubjects = async () => {
    try {
      let response = await axios(API_ENDPOINTS.SUBJECTS, {
        method: "GET",
        headers: {
          "content-Type": "application/json",
        },
      });
      let data = await response.data;

      const filteredSubjects = data.filter((subject) => subject.year === year);

      // this sets the id of the fist subject to be dispayed on initial load
      if (filteredSubjects.length > 0) {
        // Set the id of the first subject to be displayed on initial load
        setSelectedSubject(filteredSubjects[0].id);
      }

      setSubjects(data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    } finally {
      setLoading(false); // Set loading to false when done
    }
  };

  let getUnits = async () => {
    try {
      let response = await axios(API_ENDPOINTS.UNITS, {
        method: "GET",
        headers: {
          "content-Type": "application/json",
        },
      });
      let data = await response.data;

      setUnits(data);
    } catch (error) {
      console.error("Error fetching units:", error);
    } finally {
      setLoading(false); // Set loading to false when done
    }
  };

  let getPapers = async () => {
    try {
      let response = await axios(API_ENDPOINTS.PAPERS, {
        method: "GET",
        headers: {
          "content-Type": "application/json",
        },
      });
      let data = await response.data;

      setPapers(data);
    } catch (error) {
      console.error("Error fetching Papers:", error);
    } finally {
      setLoading(false); // Set loading to false when done
    }
  };

  // getting the notes from the backend
  let getNotes = async () => {
    try {
      let response = await axios(API_ENDPOINTS.NOTES, {
        method: "GET",
        headers: {
          "content-Type": "application/json",
        },
      });
      let data = await response.data;

      setNotes(data);
    } catch (error) {
      console.error("Error fetching Notes:", error);
    } finally {
      setLoading(false); // Set loading to false when done
    }
  };

  // Filter units based on subject id
  const filteredUnits = units.filter((unit) =>
    subjects.some(
      (subject) => unit.subject === subject.id && subject.year === year
    )
  );
  const filteredSubjects = subjects.filter((subject) => subject.year === year);

  const handleSubjectClick = (subject) => {
    setSelectedSubject(subject);
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value.toLowerCase();

    // Filter units based on the search input
    const filteredUnitsForSearch = filteredUnits.filter((unit) =>
      unit.unit.toLowerCase().includes(inputValue)
    );

    setFilteredUnitsBySearch(filteredUnitsForSearch);
  };

  const handleSelectedTab = (activeTab) => {
    setSelectedTab(activeTab);
  };

  return (
    <div>
      <div className="yearPage__header">
        <NavBar navLinks={null} />
      </div>

      <div className="yearPage__content">
        {loading ? (
          <Loading />
        ) : (
          <>
            <h1>Year {year}</h1>
            <div className="yearPage__content__main">
              <div className="yearPage__content__sidebar">
                <ListGroup
                  subjects={filteredSubjects}
                  onSubjectClick={handleSubjectClick}
                />
              </div>

              <div className="yearPage__content__resources">
                <div className="yearPage__content__resources__top">
                  {filteredSubjects.map((subject) => {
                    if (selectedSubject === subject.id) {
                      return (
                        <h3 key={subject.id}>{subject.subject} Resources</h3>
                      );
                    }
                    return null;
                  })}
                  <input
                    onChange={(e) => handleSearch(e)}
                    type="text"
                    placeholder="Search Units"
                  />
                </div>
                <YearTab onTabClick={handleSelectedTab} />
                <div
                  className={`${
                    selectedTab === "papers" ? "active" : "hidden"
                  }`}
                >
                  <YearContent
                    units={
                      filteredUnitsBySearch.length > 0
                        ? filteredUnitsBySearch
                        : filteredUnits
                    }
                    papers={papers}
                    selectedSubject={selectedSubject}
                  />
                </div>

                <div
                  className={` ${
                    selectedTab === "notes" ? "active" : "hidden"
                  }`}
                >
                  <NoteContent
                    units={
                      filteredUnitsBySearch.length > 0
                        ? filteredUnitsBySearch
                        : filteredUnits
                    }
                    notes={notes}
                    selectedSubject={selectedSubject}
                  />
                </div>

                <div className="upload-area">
                  <div
                    className={` ${
                      selectedTab === "papers" ? "active" : "hidden"
                    }`}
                  >
                    <Upload
                      subjects={filteredSubjects}
                      units={filteredUnits}
                      uploading={"papers"}
                    />
                  </div>
                  <div
                    className={` ${
                      selectedTab === "notes" ? "active" : "hidden"
                    }`}
                  >
                    <Upload
                      subjects={filteredSubjects}
                      units={filteredUnits}
                      uploading={"notes"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default YearPage;
