import React, { useEffect, useState } from "react";
import { NavBar } from "../../components";
import { Footer, BlogCategory, BlogBanner } from "../../containers";
import "./blog.css";
import { API_ENDPOINTS } from "../../api/services/api";
import { useParams } from "react-router-dom";
import axios from "axios";

function BlogCategoryPage(props) {
  const [blogs, setBlogs] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const category = id;

    const config = {
      headers: {
        "content-Type": "application/json",
      },
    };

    const fetchData = async () => {
      try {
        const res = await axios.post(
          API_ENDPOINTS.CATEGORY,
          { category },
          config
        );
        setBlogs(res.data);
        console.log(id, blogs);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);

  return (
    <div>
      <div className="blogPage__header">
        <NavBar navLinks={null} />
      </div>
      <BlogBanner />

      <BlogCategory blogs={blogs} category={id} />

      <Footer />
    </div>
  );
}

export default BlogCategoryPage;
