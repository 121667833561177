import React from "react";
import "./cta.css";
import { Year } from "../../components";

import { book, flask, capsules, syringe, heart, stethoscope } from "./imports";

function CTA(props) {
  const yearData = [
    {
      year: "Year 1",
      img: book,
    },
    {
      year: "Year 2",
      img: flask,
    },
    {
      year: "Year 3",
      img: capsules,
    },
    {
      year: "Year 4",
      img: syringe,
    },
    {
      year: "Year 5",
      img: heart,
    },
    {
      year: "Year 6",
      img: stethoscope,
    },
  ];
  return (
    <div className="cta" id="cta">
      <div className="cta__header">
        <h2>Explore the years</h2>
      </div>
      <div className="cta__years">
        {yearData.map((item, index) => (
          <Year img={item.img} year={item.year} key={index} />
        ))}
      </div>
    </div>
  );
}

export default CTA;
