import React from "react";
import "../../pages/blog/blog.css";

import { IoMdTime } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";
import { Link } from "react-router-dom";

function BlogCategory({ blogs, category }) {
  const linkStyle = {
    textDecoration: "none",
  };
  return (
    <div>
      <div className="container">
        <h2 className="h5 section-title">{category}</h2>
        <div className="row">
          {blogs.map((blog) => (
            <div key={blog.id} className="col-lg-4 mb-5">
              <article className="shadow article-content">
                <img
                  className="post-image"
                  src={blog.thumbnail}
                  alt="thumbnail"
                />
                <div className="card-details">
                  <Link style={linkStyle} to={`/blog/${blog.slug}`}>
                    <h3 className="post-title">{blog.title}</h3>
                  </Link>
                  <ul class="card-meta list-inline">
                    <li class="list-inline-item">
                      <div>
                        <img
                          className="author-photo"
                          src={blog.authorProfilePic}
                          alt=""
                        />
                        <span className="light-text">{blog.authorName} |</span>
                      </div>
                    </li>
                    <li class="list-inline-item ">
                      <IoMdTime className="light-text" />
                      <span className="light-text">
                        {" "}
                        {blog.readTime} Min Read |
                      </span>
                    </li>
                    <li class="list-inline-item">
                      <CiCalendarDate className="light-text" />
                      <span className="light-text">{blog.date_created} |</span>
                    </li>
                    <li class="list-inline-item">
                      <ul class="card-meta-tag list-inline">
                        <li class="list-inline-item ">
                          <Link
                            to={`/blog/category/${blog.category}`}
                            className="card-meta-tag-category"
                            style={linkStyle}
                          >
                            {blog.category}
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <p className="post-description-text">{blog.description}</p>

                  <Link style={linkStyle} to={`/blog/${blog.slug}`}>
                    <p className=" card-meta-button">Read more</p>
                  </Link>
                </div>
              </article>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BlogCategory;
