import React, { useEffect, useState } from "react";
import "../../pages/blog/blog.css";
import "./blogDetail.css";

import { IoMdTime } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";
import { Link } from "react-router-dom";
import { API_ENDPOINTS } from "../../api/services/api";
import axios from "axios";
import { ScrollProgress } from "../../components";
import { Helmet } from "react-helmet";

function BlogDetail({ blog, createBlog }) {
  const linkStyle = {
    textDecoration: "none",
  };
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    getBlogs();

    console.log("Bck");
  }, [blog]);

  let getBlogs = async () => {
    try {
      let response = await axios(API_ENDPOINTS.BLOGS, {
        method: "GET",
        headers: {
          "content-Type": "application/json",
        },
      });
      let data = await response.data;

      setBlogs(data);
      console.log(data);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };
  const lastFourBlogs = blogs.slice(-4);

  return (
    <div>
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={blog.description} />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.description} />
        <meta property="og:image" content={blog.thumbnail} />
      </Helmet>

      <div className="blog-detail-container">
        <div className="col-lg-8 mb-5 mb-lg-0">
          <article>
            <ScrollProgress />
            <h1 className="  blog-title">{blog.title}</h1>
            <div className="blogDetail-image">
              <img
                className="blogDetail-thumb-image"
                src={blog.thumbnail}
                alt="thumbnail"
              />
            </div>
            {/* the author , time and calender */}
            <ul class="card-meta list-inline">
              <li class="list-inline-item">
                <div>
                  <img
                    className="author-photo"
                    src={blog.authorProfilePic}
                    alt=""
                  />
                  <span className="light-text">{blog.authorName} |</span>
                </div>
              </li>
              <li class="list-inline-item ">
                <IoMdTime className="light-text" />
                <span className="light-text"> {blog.readTime} Min Read |</span>
              </li>
              <li class="list-inline-item">
                <CiCalendarDate className="light-text" />
                <span className="light-text">{blog.date_created} |</span>
              </li>
              <li class="list-inline-item">
                <ul class="card-meta-tag list-inline">
                  <li class="list-inline-item ">
                    <Link
                      to={`/blog/category/${blog.category}`}
                      className="card-meta-tag-category"
                      style={linkStyle}
                    >
                      {blog.category}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            {/* end of the details  */}
            {/* style={{ fontFamily: "Roboto" }} */}
            <div style={{ fontFamily: "Roboto" }}>
              <div
                style={{ fontFamily: "Roboto" }}
                className="content blog-content mt-5 mb-5"
                dangerouslySetInnerHTML={createBlog()}
              ></div>
            </div>
            <div className="widget">
              <h1>Recent posts</h1>
              {lastFourBlogs.map((blog) => (
                <div className="card-body shadow" key={blog.id}>
                  <img className="card-img-sm" src={blog.thumbnail} alt="" />
                  <div className="card-body-post-details">
                    <Link style={linkStyle} to={`/blog/${blog.slug}`}>
                      <h3 className="post-title">{blog.title}</h3>
                    </Link>
                    <ul class="card-meta list-inline">
                      <li class="list-inline-item ">
                        <IoMdTime className="light-text" />
                        <span className="light-text">
                          {" "}
                          {blog.readTime} Min Read |
                        </span>
                      </li>
                      <li class="list-inline-item">
                        <CiCalendarDate className="light-text" />
                        <span className="light-text">
                          {blog.date_created} |
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default BlogDetail;
